exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/Layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-navbar-js": () => import("./../../../src/pages/components/Navbar.js" /* webpackChunkName: "component---src-pages-components-navbar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-watch-list-js": () => import("./../../../src/pages/my-watch-list.js" /* webpackChunkName: "component---src-pages-my-watch-list-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-work-details-js": () => import("./../../../src/templates/work-details.js" /* webpackChunkName: "component---src-templates-work-details-js" */)
}

